<template>
  <div id="withdrawConfirm">
    <a-modal
      wrap-class-name="withdraw-confirm"
      :visible="visible"
      :mask-closable="false"
      :ok-text="$t('common_text_041')"
      :title="$t('pages_pay_135')"
      :confirm-loading="loading"
      @ok="() => passwordVisible = true"
      @cancel="$emit('update:visible', false)"
    >
      <div class="header-title flex-align">
        <info-circle-filled class="ic-confirm" />
        <span>{{ $t('pages_pay_136') }}</span>
      </div>
      <div class="info-body">
        <div class="area flex-align">
          <span>{{ $t('pages_pay_123') }}</span>
          <div class="flex-align" style="margin-left: auto">
            <img :src="getCountryLogo(withdrawInfo.currency)" class="img-country">
            <span>{{ withdrawInfo.currency }}</span>
          </div>
        </div>
        <div class="area flex-align">
          <span>{{ $t('pages_pay_125') }}</span>
          <span>{{ parseMoney(withdrawInfo.amount) }}</span>
        </div>
        <div class="area flex-align">
          <span>{{ $t('pages_pay_132') }}</span>
          <span>{{ withdrawInfo.bankName }} - {{ withdrawInfo.bankNo }}</span>
        </div>
        <div class="area flex-align">
          <span>{{ $t('pages_pay_082') }}</span>
          <span>{{ withdrawInfo.fee }} {{ withdrawInfo.feeCurrency }}</span>
        </div>
      </div>
    </a-modal>
    <password-modal
      v-model:visible="passwordVisible"
      @pass="handlePass"
    />
  </div>
</template>
<script>
import { reactive, toRefs, h } from 'vue'
import { useRouter } from 'vue-router'
import { parseMoney, getCountryLogo } from '@/util'
import { notification, message, Button } from 'ant-design-vue'
import PasswordModal from '@/components/password-modal'
import i18n from '@/locale'
import { _pay } from '@/api'
export default {
  name: 'WithdrawConfirm',
  components: {
    'password-modal': PasswordModal
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    withdrawInfo: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, ctx) {
    const router = useRouter()

    const state = reactive({
      loading: false,
      passwordVisible: false
    })

    const handleSubmit = async () => {
      const res = await _pay.isPayPwdExist()
      if (res.data.data) {
        state.passwordVisible = true
      } else {
        Modal.warning({
          title: i18n.global.t('common_text_037'),
          content: i18n.global.t('common_text_038') + i18n.global.t('common_text_039'),
          okText: i18n.global.t('common_text_039'),
          onOk() {
            router.push({ path: '/user/account_security', query: { labName: 'payPwd' }})
          }
        })
      }
    }

    const handlePass = () => {
      state.loading = true
      const { amount, bankNo, currency, remark, rateId, feeCurrency } = props.withdrawInfo
      const data = { amount, bankNo, currency, remark, rateId, feeCurrency }
      _pay.withdrawApply(data)
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            ctx.emit('update:visible', false)
            ctx.emit('refresh')
            notification.success({
              message: i18n.global.t('pages_pay_137'),
              description: i18n.global.t('pages_pay_138'),
              btn: () => {
                return h(Button, {
                  type: 'primary',
                  size: 'small',
                  onClick: () => {
                    notification.destroy()
                    router.push({ path: '/account/details', query: { labName: 'withdraw' }})
                  }
                }, i18n.global.t('pages_pay_139'))
              }
            })
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    return {
      handlePass,
      handleSubmit,
      parseMoney,
      getCountryLogo,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.withdraw-confirm {
  .header-title {
    margin-top: -8px;
    font-weight: 500;
  }
  .ic-confirm {
    color: @main-color;
    font-size: 18px;
    margin-right: 8px;
  }
  .info-body {
    padding: 12px 30px 0 30px;
    .area {
      margin-top: 18px;
      .img-country {
        margin-right: 12px;
        width: 28px;
      }
      span:nth-child(2) {
        margin-left: auto;
      }
    }
  }
}
</style>
