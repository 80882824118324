<template>
  <div id="withdraw">
    <header-nav :content="`${$t('pages_router_007')} / ${ $t('pages_pay_122')}`" />
    <div v-if="kycFlag" class="kyc-alert">
      <a-alert v-if="kycStatus === 'CUSTOMER'" banner>
        <template #message>
          <span>{{ $t('pages_pay_161') }}</span>
          <a>{{ $t('pages_pay_162') }}</a>
        </template>
      </a-alert>
      <a-alert v-else-if="kycStatus === 'CUSTREJ'" banner>
        <template #message>
          <span>{{ $t('pages_pay_160') }}</span>
          <a>{{ $t('pages_pay_162') }}</a>
        </template>
      </a-alert>
      <a-alert v-else :message="$t('pages_pay_159')" banner />
    </div>
    <div v-else class="common-card-body">
      <div style="display: flex">
        <a-form
          ref="withdrawRef"
          class="ant-round-form withdraw-form"
          layout="vertical"
          :model="withdrawForm"
          :rules="withdrawRules"
          @finish="applySubmit"
        >
          <a-form-item name="currency">
            <template #label>
              <div class="flex-align">
                <span>{{ $t('pages_pay_123') }}</span>
                <span v-if="availableAmount !== null" class="amount-text">{{ $t('pages_pay_124') }}: {{ parseMoney(availableAmount) }}</span>
              </div>
            </template>
            <a-select
              v-model:value="withdrawForm.currency"
              show-search
              allow-clear
              option-label-prop="label"
              option-filter-prop="label"
              :placeholder="$t('common_text_005') + $t('pages_pay_123')"
              @change="curChange"
            >
              <a-select-option
                v-for="item in curList"
                :key="item.currency"
                :value="item.currency"
                :label="`${item.currency} - ${item.currencyName}`"
              >
                <img :src="getCountryLogo(item.currency)" class="select-img-country">
                <span>{{ item.currency }} - {{ item.currencyName }}</span>
                <span style="margin-left: 8px; color: #999; font-size: 12px">
                  {{ $t('pages_pay_124') }}: {{ item.amountText }}
                </span>
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('pages_pay_125')" name="amount">
            <a-input v-model:value="withdrawForm.amount" allow-clear :placeholder="$t('common_text_006') + $t('pages_pay_125')" />
          </a-form-item>
          <a-form-item name="account">
            <template #label>
              <div class="flex-align">
                <span>{{ $t('pages_pay_132') }}</span>
                <a-tooltip :title="$t('pages_pay_153')">
                  <contacts-filled class="ic-user" @click="() => insertVisible = true" />
                </a-tooltip>
              </div>
            </template>
            <a-select v-model:value="withdrawForm.account" :placeholder="$t('common_text_005') + $t('pages_pay_132')" @change="accountChange">
              <a-select-option
                v-for="item in accountList"
                :key="item.id"
                :value="String(item.id)"
              >{{ item.bankName }} - {{ item.bankNo }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="remark" :label="$t('pages_pay_133')">
            <a-textarea v-model:value="withdrawForm.remark" :placeholder="$t('common_text_006') + $t('pages_pay_133')" class="input-primary" allow-clear />
          </a-form-item>
          <a-form-item style="margin-top: 48px">
            <a-button
              shape="round"
              class="withdraw-btn"
              type="primary"
              ghost
              @click="formReset"
            >{{ $t('common_text_040') }}</a-button>
            <a-button
              :loading="loading"
              html-type="submit"
              type="primary"
              shape="round"
              style="margin-left: 18px"
              class="withdraw-btn"
            >{{ $t('common_text_002') }}</a-button>
          </a-form-item>
        </a-form>
        <div class="account-info">
          <div v-if="accountInfo.id">
            <div class="info-title">{{ $t('pages_pay_140') }}</div>
            <div class="content">
              <div class="content-left">
                <div class="name">{{ $t('pages_pay_141') }}:</div>
                <div class="name">{{ $t('pages_pay_142') }}:</div>
                <div class="name">{{ $t('pages_pay_143') }}:</div>
                <div class="name">{{ $t('pages_pay_148') }}:</div>
                <div class="name">SWIFT CODE:</div>
                <div class="name">{{ $t('pages_pay_144') }}:</div>
                <div class="name">{{ $t('pages_pay_145') }}:</div>
                <div class="name">{{ $t('pages_pay_146') }}:</div>
                <div class="name">{{ $t('pages_pay_147') }}:</div>
              </div>
              <div class="content-right">
                <div class="name"><a-tooltip :title="accountInfo.accountName">{{ accountInfo.accountName }}</a-tooltip></div>
                <div class="name"><a-tooltip :title="accountInfo.bankNo">{{ accountInfo.bankNo }}</a-tooltip></div>
                <div class="name"><a-tooltip :title="accountInfo.bankName">{{ accountInfo.bankName }}</a-tooltip></div>
                <div class="name"><a-tooltip :title="accountInfo.bankAddr">{{ accountInfo.bankAddr }}</a-tooltip></div>
                <div class="name"><a-tooltip :title="accountInfo.swiftCode">{{ accountInfo.swiftCode }}</a-tooltip></div>
                <div class="name"><a-tooltip :title="accountInfo.currencies">{{ accountInfo.currencies }}</a-tooltip></div>
                <div class="name"><a-tooltip :title="accountInfo.proxyBankName">{{ accountInfo.proxyBankName }}</a-tooltip></div>
                <div class="name"><a-tooltip :title="accountInfo.proxyBankAddr">{{ accountInfo.proxyBankAddr }}</a-tooltip></div>
                <div class="name"><a-tooltip :title="accountInfo.proxyBankSwiftCode">{{ accountInfo.proxyBankSwiftCode }}</a-tooltip></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="withdraw-tip">
        <div class="tip-title flex-align">
          <span><alert-filled class="ic-tip" /></span>
          {{ $t('pages_pay_149') }}
        </div>
        <div class="content">
          <div>{{ $t('pages_pay_150') }}</div>
          <div>{{ $t('pages_pay_151') }}</div>
          <div>{{ $t('pages_pay_152') }}</div>
        </div>
      </div>
    </div>
    <confirm-modal
      v-model:visible="visible"
      :withdraw-info="withdrawForm"
      @refresh="withdrawRefresh"
    />
    <account-insert
      v-model:visible="insertVisible"
      :cur-list="curList"
      @refresh="loadAccountList"
    />
  </div>
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { _pay } from '@/api'
import HeaderNav from '@/components/header-nav'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
import ConfirmModal from './components/confirm-modal'
import AccountInsert from './components/account-insert'
import { parseMoney, getCountryLogo } from '@/util'
export default {
  name: 'Withdraw',
  components: {
    'header-nav': HeaderNav,
    'confirm-modal': ConfirmModal,
    'account-insert': AccountInsert
  },
  setup(props) {
    const withdrawRef = ref(null)
    const validatorAmount = (rule, value) => {
      if (!value) {
        return Promise.reject(i18n.global.t('pages_pay_126'))
      } else if (!Number.isFinite(+value)) {
        return Promise.reject(i18n.global.t('pages_pay_127'))
      } else if (value < 100) {
        return Promise.reject(i18n.global.t('pages_pay_128'))
      } else if ((value + '').split('.')[0].length > 16) {
        return Promise.reject(i18n.global.t('pages_pay_129'))
      } else if (state.withdrawForm.currency === 'JPY' && (value + '').split('.').length > 1) {
        return Promise.reject(i18n.global.t('pages_pay_130'))
      } else if ((value + '').split('.').length > 1 && (value + '').split('.')[1].length > 2) {
        return Promise.reject(i18n.global.t('pages_pay_131'))
      } else if (+value > state.availableAmount) {
        return Promise.reject(i18n.global.t('pages_pay_134'))
      }
      return Promise.resolve()
    }

    const store = useStore()

    const state = reactive({
      kycFlag: false,
      kycStatus: '',
      loading: false,
      visible: false,
      insertVisible: false,
      withdrawForm: {
        amount: null,
        currency: null,
        account: null,
        remark: '',
        bankName: '',
        bankNo: '',
        fee: '',
        rateId: '',
        feeCurrency: ''
      },
      accountInfo: {},
      availableAmount: null,
      curList: [],
      accountList: [],
      baseAccountList: [],
      withdrawRules: {
        currency: [{ required: true, message: i18n.global.t('pages_pay_123') + i18n.global.t('common_text_042') }],
        amount: [{ required: true, validator: validatorAmount }],
        account: [{ required: true, message: i18n.global.t('pages_pay_132') + i18n.global.t('common_text_042') }]
      }
    })

    const curChange = async (currency) => {
      state.withdrawForm.rateId = ''
      state.withdrawForm.account = null
      state.withdrawForm.amount = null
      state.availableAmount = null
      state.accountList = []
      state.accountInfo = {}
      if (currency) {
        state.accountList = state.baseAccountList.filter(item => item.currencies.includes(currency))
        const account = state.curList.find(item => item.currency === currency)
        if (account.amount > 0) {
          const res = await _pay.getWithdrawAvailableAmount({
            amount: account.amount,
            bankNo: 'calculate',
            currency
          })
          if (res.data.succ) {
            const { data } = res.data
            state.availableAmount = data.availableAmount
            state.withdrawForm.rateId = data.rateId
          } else {
            message.error(res.data.resMsg)
          }
        } else {
          state.availableAmount = 0
        }
      }
    }

    const accountChange = (accountNo) => {
      state.accountInfo = state.accountList.find(item => String(item.id) === accountNo)
      state.withdrawForm.bankName = state.accountInfo.bankName
      state.withdrawForm.bankNo = state.accountInfo.bankNo
    }

    const formReset = () => {
      withdrawRef.value.resetFields()
      state.availableAmount = null
      state.accountInfo = {}
    }

    const withdrawRefresh = () => {
      formReset()
      store.dispatch('getCurList')
    }

    const applySubmit = async () => {
      state.loading = true
      const { amount, currency, rateId } = state.withdrawForm
      const res = await _pay.getWithdrawFee({
        amount,
        bankNo: 'calculate',
        currency,
        rateId
      })
      if (res.data.succ) {
        const { data } = res.data
        state.withdrawForm.fee = data.feeAmtTotal
        state.withdrawForm.feeCurrency = data.feeCurrency
        state.visible = true
      } else {
        message.error(res.data.resMsg)
      }
      state.loading = false
    }

    const loadCurList = async () => {
      const res = await _pay.getRechargeSup()
      if (res.data.succ) {
        state.curList = res.data.data.currencyList.map(item => {
          return Object.assign({}, item, {
            amountText: parseMoney(item.amount, item.currency)
          })
        })
      }
    }

    const loadAccountList = async () => {
      const res = await _pay.getWithdrawAccountList()
      if (res.data.succ) {
        state.baseAccountList = res.data.data
      }
    }

    const { status } = store.getters.getUserInfo

    if (status === 'OPENFINSH') {
      loadCurList()
      loadAccountList()
    } else {
      state.kycStatus = status
      state.kycFlag = true
    }

    return {
      curChange,
      accountChange,
      withdrawRefresh,
      withdrawRef,
      getCountryLogo,
      applySubmit,
      parseMoney,
      formReset,
      loadAccountList,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#withdraw {
  text-align: left;
  .kyc-alert {
    margin-top: 24px;
  }
  .common-card-body {
    padding: 18px 24px;
    .withdraw-form {
      flex: 1;
      padding-right: 24px;
      .amount-text {
        margin-left: 8px;
        color: #999;
        font-size: 12px;
      }
      .ic-user {
        margin: -2px 0px 0 5px;
        color: @main-color;
        font-size: 18px;
        cursor: pointer;
      }
      .input-primary:deep(.ant-input) {
        border-radius: 5px;
      }
      .withdraw-btn {
        min-width: 138px;
      }
    }
    .account-info {
      flex: 1;
      padding-left: 24px;
      overflow: hidden;
      .info-title {
        font-weight: 500;
      }
      .content {
        display: flex;
        overflow: hidden;
        margin-top: 8px;
        .content-left {
          flex: 1;
          background: @table-th-color;
          padding: 0 15px;
          text-align: center;
          border-radius: 8px 0 0 8px;
          .name {
            height: 50px;
            line-height: 50px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .content-right {
          flex: 3;
          border: 1px solid #EFEFEF;
          border-radius: 0 8px 8px 0;
          overflow: hidden;
          .name {
            height: 50px;
            line-height: 50px;
            padding: 0 24px;
            border-bottom: 1px solid #EFEFEF;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .name:last-child {
            border: 0;
          }
        }
      }
    }
    .withdraw-tip {
      margin-top: 48px;
      .tip-title {
        font-weight: 500;
        .ic-tip {
          color: @main-color;
          margin: 0 8px 6px 0;
        }
      }
      .content {
        margin-top: 12px;
        color: #666;
        font-size: 12px;
        div {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>

